import React, { useState } from 'react';
import { Radio, type RadioChangeEvent } from 'antd';
// import { Radio } from 'antd';

interface IWizrRadioGroup {
    options?: any
    onChange?: (value: any) => void
    value?: string
    disabled?: any
}

const RadioGroup = ({ options, onChange, value, disabled }: IWizrRadioGroup): JSX.Element => {

    const trackStyle = {
        backgroundColor: 'black', // Set the desired background color
        height: 1, // Set the desired height of the track
      }
    
        return (
            <Radio.Group
                options={options}
                onChange={onChange}
                value={value}
                disabled={disabled}
                // optionType="button"
                // buttonStyle="solid"
            />
        )
    }

export default RadioGroup;


{/* <Radio.Group
        options={options}
        onChange={onChange}
        value={value}
        // optionType="button"
        // buttonStyle="solid"
    /> */}