import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import WizrText from './components/WizrText';
import WizrIcon from './components/WizrIcon';
import AIIcon from './assets/icons/AIIcon';
import EndUser from './assets/icons/EndUser';
import { useFeatureSettingSelector, useGetTenantDetails, usePermissionValues } from './redux/selectors/content';
import { dispatchFeatureSettings, dispatchSetPermissionValues } from './redux/actions/content';
import CXHubIcon from './assets/icons/CXHubIcon';
import WizrFlexLayout from './components/WizrFlexLayout';

interface AuthContextType {
    userPermission: string | null;
    hasAcces: (role: string) => any;
    filteredMenuItems: any[];
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};


export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {

    const [userPermission, setUserPermission] = useState<string | null>(null); 
    
    // const permissionValues = [
    //     // home
    //     'view_home', 'view_app-designer', 'view_contents', 'run_bulkRun', 
    //     // contents
    //     'view_folder', 'create_folder', 'update_folder', 'delete_folder', 'view_file', 'delete_file', 'upload_file', 'update_file',
    //     'view_past_tickets', 'create_ptfolder', 'upload_ptfile', 'import_ptfile', 'update_ptfolder', 'delete_ptfolder', 'delete_ptfile', 'update_ptfile',
    //     'view_faq', 'view_file', 'upload_faqfile', 'create_faqfolder', 'update_faqfolder', 'delete_faqfolder', 'delete_faqfolder', 'update_faqfolder',
    //     // cxsettings
    //     'view_cxsettings', 'view_integrations', 'add_integrations', 'edit_integrations',
    //     'view_autosolve', 'edit_autosolve', 'clone_autosolve', 'create_autosolve', 'delete_autosolve', 'enabledisable_autosolve',
    //     'view_widgets', 'create_widgets', 'edit_widget', 'delete_widget',
    //     'view_chatbot', 'edit_bot', 'delete_bot', 'create_bot', 'test_bot',
    //     'view_autosolvechatbot',
    //     'view_chatbotlisting',
    //     'view_agentassist', 'create_agentassist', 'clone_agentassist', 'edit_agentassist',
    //     'view_autotag', 'edit_autotag',
    //     'view_agenttag', 'edit_agenttag',
    //     'view_featuregroup', 'view_ticketsettings', 'view_company',
    //     'view_controlroom', 
    //     'view_customerInteractions',
    //     // app-designer
    //     'view_prompt', 'create_prompt', 'clone_prompt', 'edit_prompt', 
    //     'view_prompt-view', 'publish_prompt',
    //     'view_prompt-history', 'download_promptHistory',
    //     'view_app-designer', 'create_app', 'run_app', 'edit_app', 'create_packages', 'test_app', 'delete_app', 'clone_app', 'publish_app',
    //     'view_app-history', 
    //     'view_runhistory', 'view_bulkrun', 'run_bulkRun', 
    //     'view_bulkrunlist', 'share_app',
    //     'view_appInsights',
    //     // settings 
    //     'view_settings', 'view_profile', 'view_product', 'view_products', 'view_packages', 'view_roles', 'view_userroles', 'create_roles',
    //     'edit_roles', 'view_users', 'view_userdetails' 
    //  ]
    const permissionValues: any = usePermissionValues();
    const tenantDetails = useGetTenantDetails()

    useEffect(() => {
        const appshare = location?.pathname?.includes('/appshare')
        const login = location?.pathname?.includes('/login') || location?.pathname?.includes('/')
        const extension = location?.pathname?.includes('/extension')
        const errorPage = location?.pathname?.includes('./error')
        !appshare && !extension && !login && !errorPage && dispatchFeatureSettings()
    }, [])

    const settings = useFeatureSettingSelector()
    const featureSettings = settings?.feature_settings
    // const autoTag = featureSettings?.filter((item: any) => item?.setting_type === 'autotag')
    // const agentassist = featureSettings?.filter((item: any) => item?.setting_type === 'agentassist')

    const hasAcces = (permission: string) => {
        return permissionValues.includes(permission)
    };    

    // const autosolve = featureSettings?.filter((item: any) => item?.setting_type === 'autosolve')
    // const triage = featureSettings?.filter((item: any) => item?.setting_type === 'triage')

    const topMenuItems = [
        {
            key: 'home',
            label: <WizrText type='body2'>Home</WizrText>,
            icon: <WizrIcon name='home' />,
            navigateTo: '/dashboard',
        },
        {
            key: 'cxsettings',
            label: <WizrText type='body2'>CX Hub</WizrText>,
            icon: <CXHubIcon />,
            children: [
                hasAcces('view_controlroom') &&
                {
                    key: 'controlroom',
                    label: (
                        <WizrFlexLayout flexDirection='row' alignItems='center' background='transparent'>
                            <WizrText type='body2'>Control Room</WizrText>
                            <WizrText type='captionItalic' textColor='#999B9F'> Coming Soon</WizrText>
                        </WizrFlexLayout>
                    ),
                    // navigateTo: '/cxcontrol',
                },
                hasAcces('view_agentassist') && {
                    key: 'cxsettings/agentassist',
                    label: <WizrText type='body2'>Agent Assist</WizrText>,
                    navigateTo: 'cxsettings/agentassist',
                },
                hasAcces('view_autosolve') && {
                    key: 'cxsettings/autosolve',
                    label: <WizrText type='body2'>Auto Solve</WizrText>,
                    navigateTo: 'cxsettings/autosolve',
                },
                hasAcces('view_autotag') &&
                {
                    key: 'cxsettings/autotag',
                    label: <WizrText type='body2'>Auto Tag</WizrText>,
                    navigateTo: 'cxsettings/autotag',
                },
                hasAcces('view_agenttag') &&
                {
                    key: 'cxsettings/agenttag',
                    label: <WizrText type='body2'>Agent Tag</WizrText>,
                    navigateTo: 'cxsettings/autotag',
                },
                hasAcces('view_cxsettings') &&
                {
                    key: 'cxsettings',
                    label: <WizrText type='body2'>CX Settings</WizrText>,
                    navigateTo: '/cxsettings',
                },
                hasAcces('view_customerInteractions') &&
                {
                    key: 'customerInteractions',
                    label: <WizrText type='body2'>Customer Interactions</WizrText>,
                    navigateTo: '/customerInteractions',
                },
            ],
        },

        {
            key: 'folder',
            label: <WizrText type='body2'>Content</WizrText>,
            icon: <WizrIcon name='notes' />,
            children: [
                hasAcces('view_folder') &&
                {
                    key: 'folder',
                    label: <WizrText type='body2'>Knowledge Base</WizrText>,
                    navigateTo: '/folder',
                },
                hasAcces('view_past_tickets') &&
                {
                    key: 'past_tickets',
                    label: <WizrText type='body2'>Past Tickets</WizrText>,
                    navigateTo: '/past_tickets',
                },
                hasAcces('view_faq') &&
                {
                    key: 'faq',
                    label: <WizrText type='body2'>FAQ</WizrText>,
                    navigateTo: '/folder',
                },
            ],
            // navigateTo: '/folder',
        },
        {
            key: 'prompt',
            label: <WizrText type='body2'>AI Studio</WizrText>,
            icon: <AIIcon stroke='#999B9F' />,
            children: [
                hasAcces('view_prompt') &&
                {
                    key: 'prompt',
                    label: <WizrText type='body2'>Prompts</WizrText>,
                    navigateTo: '/prompt',
                },
                hasAcces('view_app-designer') &&
                {
                    key: 'app-designer',
                    label: <WizrText type='body2'>App Designer</WizrText>,
                    navigateTo: '/app-designer',
                },
                hasAcces('view_appInsights') &&
                {
                    key: 'appInsights',
                    label: (<WizrFlexLayout flexDirection='row' alignItems='center' background='transparent'>
                        <WizrText type='body2'>App Insights</WizrText>
                        {/* <WizrText type='captionItalic' textColor='#999B9F'> Coming Soon</WizrText> */}
                    </WizrFlexLayout>),
                    navigateTo: '/appInsights',

                },

            ],
        },
        {
            key: 'settings',
            label: <WizrText type='body2'>Settings</WizrText>,
            icon: <WizrIcon name='settings' />,
            navigateTo: '/settings',
        },
        ...(tenantDetails?.master_tenant ? [
            {
                key: 'euserapplisting',
                label: <WizrText type='body2'>End User</WizrText>,
                icon: <EndUser />,
                children: [
                    {
                        key: 'euserapplisting',
                        label: <WizrText type='body2'>Content Generation</WizrText>,
                        navigateTo: '/euserapplisting',
                    },
                    {
                        key: 'chatbotlisting',
                        label: <WizrText type='body2'>Chat Bot</WizrText>,
                        navigateTo: '/chatbotlisting',
                    },
                ],
                navigateTo: '/euserapplisting',
            },] : []),
    ]



    // const filteredMenuItems = topMenuItems
    const filteredMenuItems = topMenuItems.filter(item => hasAcces(`view_${item.key}`));

    //     const filteredMenuItems = topMenuItems.filter(item => {
    //     if (item.key) {
    //         const permissionKey = `view_${item.key}`;
    //         return permissionValues.includes(permissionKey);
    //     }

    //     if (item.children) {
    //         item.children = item.children.filter(child => {
    //             if (child.key) {
    //                 const permissionKey = `view_${child.key}`;
    //                 return permissionValues.includes(permissionKey);
    //             }
    //             return true; // Include items without a key
    //         });

    //         return item.children.length > 0;
    //     }

    //     return true; // Include items without a key
    // });



    useEffect(() => {
        if (localStorage.getItem('id_token')) {
            const idToken: any = localStorage.getItem('id_token');
            dispatchSetPermissionValues(idToken)
        }

    }, [])

    return (
        <AuthContext.Provider value={{ userPermission, hasAcces, filteredMenuItems }}>
            {children}
        </AuthContext.Provider>
    );
};
