import { Button, ButtonProps } from 'antd'
import { styled } from 'styled-components'
import WizrText from './WizrText'

interface IWizrButton extends ButtonProps {
  icon?: any
  size?: any
  text?: any
  customStyle?: any
  padding?: string | number
  justifyContent?: string
  color?:any
  disabled?: any
  textColor?:any
}

const StyledButton = styled<any>(Button)`
  color: var(--grayscale-800, #1d1f22);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: ${(p) => (p.justifyContent ? p.justifyContent : 'center')};
  padding: ${(p) => (p.padding ? p.padding : '4px 15px')};
  
`

const WizrButton = ({
  icon = null,
  size = 'middle',
  text,
  type = 'primary',
  color,
  disabled,
  ...props
}: IWizrButton): JSX.Element => {
  return (
    <StyledButton type={type} icon={icon} size={size} {...props} disabled={disabled} >

      <WizrText type='button' textColor={type === 'text' ? '#000' : color}>
        {text}
      </WizrText>
    </StyledButton>
  )
}

export default WizrButton
