import { Form } from 'antd'
import { useEffect, useState } from 'react'
import WizrInput from '../WizrInput'
import WizrTextArea from '../WizrTextArea'
import WizrFlexSpacing from '../WizrFlexSpacing'
import WizrButton from '../WizrButton'
import WizrFlexLayout from '../WizrFlexLayout'
import { styled } from 'styled-components'
import { useAutoSolveMetaData, useContentLoadingSelector } from '../../redux/selectors/content'
import { dispatchAutoSolveMetaData } from '../../redux/actions/content'

const StyledContainer = styled(WizrFlexLayout)`
  gap: 8px;
`

interface IAddName {
  name?: string
  description?: string
  onFormSubmission: (params: { [key: string]: any }) => void
  onCancel: () => void
}

const AddName = ({
  name,
  description,
  onFormSubmission,
  onCancel,
}: IAddName): JSX.Element => {
  const [form] = Form.useForm()
const loading = useContentLoadingSelector()
  useEffect(() => {
    form.setFieldValue('currentname', name)
    form.setFieldValue('description', description)
  }, [name,description])
  return (
    <StyledContainer background='#FFF' justifyContent='space-evenly'>
      <Form
        name='basic'
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFormSubmission}
        onFinishFailed={() => null}
        autoComplete='off'
      >
        <Form.Item
          name='currentname'
          rules={[{ required: true, message: 'Please Enter a  Name', whitespace: true }]}
        >
          <WizrInput
            placeholder='Enter Name'
            onChange={(value: string) => form.setFieldsValue({ currentname: value })}
          />
        </Form.Item>
        <Form.Item
          name='description'
        >
          <WizrTextArea placeholder='Enter Description' />
        </Form.Item>
       
        <Form.Item>
          <WizrFlexSpacing
            justifyContent='flex-end'
            flexDirection='row'
            size='large'
            background='#FFF'
          >
            <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '20px'}}/>
            <WizrButton htmlType='submit' text={'Create'} disabled={loading} style={{borderRadius: '20px'}}/>
          </WizrFlexSpacing>
        </Form.Item>
      </Form>
    </StyledContainer>
  )
}

export default AddName
