import styled from 'styled-components'
import WizrFlexLayout from '../WizrFlexLayout'
import WizrText from '../WizrText'
import WizrDropDown from '../WizrDropDown'
import { useEffect, useState } from 'react'
import { useGetIntegrationList, useGetMasterIntegrationList } from '../../redux/selectors/content'
import {
  dispatchGetIntegrationForTools,
  dispatchGetIntegrationToolsForTenants,
  dispatchImportPastTickets,
  dispatchMasterIntegrationList,
} from '../../redux/actions/content'
import ZendeskIcon from '../../assets/icons/ZendeskIcon'
import { DatePicker } from 'antd'
import WizrButton from '../WizrButton'
import { set } from 'react-hook-form'
import dayjs, { Dayjs } from 'dayjs'
import { useParams } from 'react-router-dom'
import Freshdesk from '../../assets/icons/FreshdeskIcon'
import Salesforce from '../../assets/icons/Salesforce'

const InputContainer = styled(WizrFlexLayout)`
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  background-color: #fff;
`
const StyledDropDown = styled(WizrDropDown)`
  height: 50px;
  .ant-select-selector {
    padding-top: 10px !important;
    height: 50px !important;
    background-color: #f2f4f9 !important;
    .ant-select-selection-placeholder {
      margin-top: 10px;
    }
  }
`

const StyledDatePicker = styled(DatePicker)`
  background-color: #f2f4f9 !important;
  width: 100% !important;
  height: 50px !important;
`

const StyledButton = styled(WizrButton)`
  height: 40px !important;
  width: 90px !important;
  border-radius: 20px !important;
  border: 1px solid black !important;
`

const ImportPastTickets = ({ folderId, onCancel }: any) => {
  const [currentDetails, setCurrentDetails] = useState<any>({ integration_key: '', tool: '' })

  const masterIntegrations = useGetMasterIntegrationList()
  const integrationsForTools = useGetIntegrationList()
  useEffect(() => {
    dispatchMasterIntegrationList()
    dispatchGetIntegrationToolsForTenants()
  }, [])

  const getIcon = (item: any) => {
    switch (item?.integration_key) {
      case 'zendesk':
        return <ZendeskIcon />
      case 'freshdesk':
        return <Freshdesk/> 
        case 'salesforce':
        return <Salesforce/> 
      default:
        return null
    }
  }

  const dateFormat = 'YYYY-MM-DD HH:mm:ss'
  const defaultTime = '00:00:00'

  const disabledDate = (date: Dayjs): boolean => {
    if (!currentDetails?.start_date) return false // Return false if start_date is not available

    const startDate = dayjs(currentDetails.start_date, dateFormat).startOf('day')
    return date.isBefore(startDate, 'day')
  }

  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: (
          <div style={{ alignItems: 'center', display: 'flex', gap: '10px' }}>
            <div>
              {getIcon(item)}
              {/* Render the icon component */}
            </div>
            <div>
              {' '}
              {item.display_name} {/* Render the label text */}
            </div>
          </div>
        ),
        value: item.integration_key,
      }))
    return result
  }

  const dropDownConverterIntegrations = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item?.display_name,
        value: item.integration_id,
      }))
    return result
  }

  const onToolSelection = (value: any, label: any) => {
    dispatchGetIntegrationForTools(value)

    setCurrentDetails({ ...currentDetails, tool: value })
  }

  const onChangeStartDate = (date: any, dateString: any) => {
    setCurrentDetails({ ...currentDetails, start_date: dateString })
  }
  const onChangeEndDate = (date: any, dateString: any) => {
    setCurrentDetails({ ...currentDetails, end_date: dateString })
  }

  const TicketStatus = [{
    label: "New",
    value: "new"
  }, {
    label: "Open",
    value: "open"
  }, {
    label: "Pending",
    value: "pending"
  },
  {
    label: "Hold",
    value: "hold"
  },
  {
    label: "Solved",
    value: "closed"
  },
]
  const params = useParams()
  const onImportPastTickets = () => {

    const Payload = {
      tool: currentDetails.tool,
      integration_id: currentDetails.integration_key,
      start_date: currentDetails.start_date,
      end_date: currentDetails.end_date,
      ticket_status: currentDetails?.ticket_status,
      folder_id: folderId
    }
    dispatchImportPastTickets(Payload)
  }
  return (
    <WizrFlexLayout background='white' style={{ gap: '15px', padding: '16px' }}>
      <InputContainer>
        <WizrText type='body2'>Select Tool</WizrText>
        <StyledDropDown
          value={currentDetails?.tool}
          options={dropDownConverter(masterIntegrations)}
          onChange={(value: any, label: any) => {
            onToolSelection(value, label)
          }}
        />
      </InputContainer>

      <InputContainer>
        <WizrText type='body2'>Select Integrations</WizrText>
        <StyledDropDown
          value={currentDetails?.integration_key}
          options={dropDownConverterIntegrations(integrationsForTools)}
          onChange={(value: any, label: any) => {
            setCurrentDetails({ ...currentDetails, integration_key: value })
          }}
        />
      </InputContainer>
      <InputContainer>
        <WizrFlexLayout flexDirection='row' background='white' style={{ gap: '20px' }}>
          <InputContainer>
            <WizrText type='body2'>Start Date</WizrText>

            <StyledDatePicker
              onChange={onChangeStartDate}
              showTime={{ defaultValue: dayjs(defaultTime, 'HH:mm:ss') }}
            />
          </InputContainer>
          <InputContainer>
            <WizrText type='body2'>End Date</WizrText>
            <StyledDatePicker onChange={onChangeEndDate} disabledDate={disabledDate} showTime />
          </InputContainer>
        </WizrFlexLayout>
      </InputContainer>

      <InputContainer>
        <WizrText type='body2'>Ticket Status</WizrText>
        <StyledDropDown
          value={currentDetails?.ticket_status}
          options={TicketStatus}
          onChange={(value: any, label: any) => {
            setCurrentDetails({ ...currentDetails, ticket_status: value })
          }}
        />
      </InputContainer>
      <WizrFlexLayout
        justifyContent='flex-end'
        flexDirection='row'
        background='white'
        style={{ padding: '10px', gap: '20px' }}
      >
        <StyledButton type='text' text='Cancel' onClick={onCancel}></StyledButton>
        <StyledButton
          type='primary'
          text={<WizrText type='button'>Import</WizrText>}
          onClick={() => { onImportPastTickets()
            onCancel()
           }}
        ></StyledButton>
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}
export default ImportPastTickets
