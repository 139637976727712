import { Form } from 'antd'
import { useEffect, useState } from 'react'
import WizrInput from '../WizrInput'
import WizrTextArea from '../WizrTextArea'
import WizrFlexSpacing from '../WizrFlexSpacing'
import WizrButton from '../WizrButton'
import WizrFlexLayout from '../WizrFlexLayout'
import { styled } from 'styled-components'
import WizrToggleButton from '../WizrToggleButton'
import WizrTagInput from '../WizrTagInput'
import WizrText from '../WizrText'
import { useTagsSelector, useCurrentAppDetailsSelector, useGetTenantDetails } from '../../redux/selectors/content'
import { dispatchTags } from '../../redux/actions/content'
import RadioGroup from '../WizrRadiogroup'
import WizrDropDown from '../WizrDropDown'
import { useAuth } from '../../authContext'

const StyledContainer = styled(WizrFlexLayout)`
  gap: 8px;
`
const StyledWizrInput = styled(WizrInput)`
  min-width: 25vw;
`
const InputContainer = styled(WizrFlexLayout)`
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  background-color: #fff;
`

interface ICreateAppForm {
  appData?: { [key: string]: any }
  onFormSubmission: (params: { [key: string]: any }) => void
  onCancel: () => void
}

const CreateAppForm = ({ appData, onFormSubmission, onCancel }: ICreateAppForm): JSX.Element => {

  

  useEffect(() => {
    dispatchTags()
  }
    , [])
  const tenantDetails = useGetTenantDetails()
  const { userPermission, hasAcces } = useAuth();

  const [form] = Form.useForm()
  const currentAppDetails = useCurrentAppDetailsSelector()
  const tags = useTagsSelector()
  const tagList = tags?.map((item: any) => ({
    label: item,
    value: item,
  }))

  const [appSettingState, setAppSettingState] = useState<any>(appData ? appData : {
    appName: '',
    memory: false,
    appDescription: '',
    appTags: [],
    app_category: 'general',
    app_feature: '',
    app_industry: ''
  }
  )
  useEffect(() => {
    if (appData) {
      form.setFieldsValue({
        appName: appData?.appName,
        memory: appData?.memory,
        appDescription: appData?.appDescription,
        appTags: appData?.appTags,
        app_category: appData?.app_category,
        app_feature: appData?.app_feature,
        app_industry: appData?.app_industry
      })
    }
  }, [appData])

  return (
    <StyledContainer background='#FFF' justifyContent='space-evenly'>
      <Form
        name='basic'
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFormSubmission}
        onFinishFailed={() => null}
        autoComplete='off'
      >
        <Form.Item name='appName'>
          <InputContainer>
            <WizrText type='body2'>App Name</WizrText>
            <WizrInput
              value={appSettingState.appName}
              onChange={(value: string) => {
                form.setFieldsValue({ appName: value })
                setAppSettingState({ ...appSettingState, appName: value })
              }}
            />
          </InputContainer>
        </Form.Item>

        <Form.Item name="memory">
          <InputContainer>
            <WizrText type='body2'>Memory</WizrText>
            <WizrFlexLayout
              flexDirection='row'
              alignContent='space-between'
              background='white'
              alignItems='baseline'
            >
              <WizrToggleButton
                isEnabled={appSettingState.memory}
                onChange={(e: boolean) => {
                  form.setFieldsValue({ memory: e })
                  setAppSettingState({ ...appSettingState, memory: e })
                }}
              />
            </WizrFlexLayout>
          </InputContainer>
        </Form.Item>

        <Form.Item name='appTags'>
          <InputContainer>
            <WizrText type='body2'>Tags</WizrText>
            <WizrTagInput
              options={tagList}
              value={appSettingState?.appTags}
              onChange={(value: any) => {
                form.setFieldsValue({ appTags: value })
                setAppSettingState({ ...appSettingState, appTags: value })
              }}
            />
          </InputContainer>
        </Form.Item>

        
          <Form.Item name='app_category'>
            <InputContainer>
              <WizrText type='body2'>App Type</WizrText>
              <RadioGroup
                options={
                  tenantDetails?.master_tenant
                    ? [
                      { label: 'General', value: 'general' },
                      { label: 'Template', value: 'package' },
                    ]
                    : [
                      { label: 'General', value: 'general' },
                      { label: 'Template', value: 'package' },
                    ]
                }
                value={appSettingState?.app_category}
                onChange={(e: any) => {
                  form.setFieldsValue({ app_category: e.target.value });
                  setAppSettingState({ ...appSettingState, app_category: e.target.value });
                }}
                // disabled={!hasAcces('create_packageapps')}
              />

            </InputContainer>
          </Form.Item>

        {/* tenantDetails?.master_tenant */}
        {appSettingState?.app_category === 'package' ?
          <Form.Item name='app_feature'>
            <InputContainer>
              <WizrText type='body2'>Link to CX Feature</WizrText>
              <WizrDropDown
                options={[
                  hasAcces('view_autotag') &&
                  { label: 'Autotag', value: 'autotag' },
                  hasAcces('view_autosolve') &&
                  { label: 'Autosolve', value: 'autosolve' },
                  hasAcces('view_agentassist') &&
                  { label: 'Agent Assist', value: 'agentassist' },
                  hasAcces('view_agenttag') &&
                  { label: 'Agent Tag', value: 'agenttag' },
                ].filter(Boolean)}
                value={appSettingState?.app_feature}
                placeHolder='Tenant'
                onChange={(value: string) => {
                  form.setFieldsValue({ app_feature: value })
                  setAppSettingState({ ...appSettingState, app_feature: value })
                }}
              />
            </InputContainer>
          </Form.Item> : <></>}

        {appSettingState?.app_category === 'package' ?
          <Form.Item name='app_industry'>
            <InputContainer>
              <WizrText type='body2'>Industry</WizrText>
              <WizrDropDown
                options={[
                  { label: 'E-commerce', value: 'E-commerce' },
                  { label: 'SaaS', value: 'SaaS' },
                  { label: 'others', value: 'others' }
                ]}
                value={appSettingState?.app_industry}
                placeHolder='Tenant'
                onChange={(value: string) => {
                  form.setFieldsValue({ app_industry: value })
                  setAppSettingState({ ...appSettingState, app_industry: value })
                }}
              />
            </InputContainer>
          </Form.Item> : <></>}

        <Form.Item name='appDescription' rules={[{ required: false, whitespace: true }]}>
          <InputContainer>
            <WizrText type='body2'>App Description</WizrText>
            <WizrTextArea
              value={appSettingState.appDescription}
              border='1px solid var(--grayscale-300, #DCDEE2)'
              placeholder='Description'
              onChange={(value: string) => {
                form.setFieldsValue({ appDescription: value })
                setAppSettingState({ ...appSettingState, appDescription: value })
              }}
            />
          </InputContainer>
        </Form.Item>

        <Form.Item>
          <WizrFlexSpacing
            justifyContent='flex-end'
            flexDirection='row'
            size='large'
            background='#FFF'
          >
            <WizrButton type='text' text='Cancel' onClick={onCancel} style={{ borderRadius: '20px' }} />
            <WizrButton htmlType='submit' text='Save' style={{ borderRadius: '20px' }} />
          </WizrFlexSpacing>
        </Form.Item>
      </Form>
    </StyledContainer>
  )
}

export default CreateAppForm