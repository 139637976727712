
const contentFolderApiUrls: any = {
  createFolder: process.env.REACT_APP_API_CONTENT_FOLDER_CREATEFOLDER,
  deleteFolder: (folderId: string) => process.env.REACT_APP_API_CONTENT_FOLDER_DELETEFOLDER?.replace('{folder_id}', folderId),
  listFolders: (type: string) => process.env.REACT_APP_API_CONTENT_FOLDER_LISTFOLDER?.replace('{type}', type),
  updateFolder: process.env.REACT_APP_API_CONTENT_FOLDER_UPDATEFOLDER,
  deleteFaqPtGroup: (type: string, folderId: any) => process.env.REACT_APP_API_CONTENT_MANAGEMENT_DELETE_GROUP_FAQ_PASTTICKET?.replace('{folderId}', folderId)?.replace('{type}', type),
  getIntegrationsToolsForTenants: process.env.REACT_APP_API_CONTENT_MANAGEMENT_GET_INTEGRATION_TOOLS_FOR_TENANTS,
  getIntegrationsForTools: (tool: any) => process.env.REACT_APP_API_CONTENT_MANAGEMENT_GET_INTEGRATION_FOR_SELECTED_TOOLS?.replace('{tool}', tool),
  importPastTickets: process.env.REACT_APP_API_CONTENT_MANAGEMENT_IMPORT_PAST_TICKETS
}

const fileManagerAPIUrls: any = {
  listFiles: (folderId: string) => process.env.REACT_APP_API_FILE_MANAGER_LISTFILE?.replace("{folder_id}", folderId),
  updateFile: process.env.REACT_APP_API_FILE_MANAGER_UPDATEFILE,
  deleteFile: (fileId: string) =>
    process.env.REACT_APP_API_FILE_MANAGER_DELETEFILE?.replace("{file_id}", fileId),
  listRowContents: (type: any, folderId: any) => process.env.REACT_APP_API_CONTENT_MANAGEMENT_GET_GROUP_CONTENT?.replace('{type}', type)?.replace('{folderId}', folderId),
  deleteRowContent: (type: any, rowId: any) => process.env.REACT_APP_API_CONTENT_MANAGEMENT_DELETE_GROUP_CONTENT?.replace('{type}', type)?.replace('{rowId}', rowId),
  deleteTicketsFaqPt: (rowId: any, type: any) => process.env.REACT_APP_API_CONTENT_MANAGEMENT_DELETE_TICKET_FAQ_PASTTICKET?.replace('{row_id}', rowId)?.replace('{type}', type),
}

const promptManagerAPIUrls: any = {
  listPromptTemplates: process.env.REACT_APP_API_PROMPT_MANAGER_TEMPLATELIST,
  listPromptsPublished: process.env.REACT_APP_API_PROMPT_MANAGER_LIST,
  listPromptsDraft: process.env.REACT_APP_API_PROMPT_MANAGER_LIST_DRAFT,

  addPromptFeedback: process.env.REACT_APP_API_PROMPT_MANAGER_FEEDBACK,
  managePrompts: process.env.REACT_APP_API_PROMPT_MANAGER_MANAGE,
  listPromptHistory: (promptId: string) => process.env.REACT_APP_API_PROMPT_MANAGER_TESTLIST?.replace("{prompt_id}", promptId),
  publishPrompt: process.env.REACT_APP_API_PROMPT_MANAGER_PUBLISH,
  getPromptDetails: (id: string, type: string) => process.env.REACT_APP_API_PROMPT_MANAGER_DETAILS?.replace("{id}", id),
  getPromptTemplateDetails: (id: string, type: string) => process.env.REACT_APP_API_PROMPT_MANAGER_DETAILS_TEMPLATE?.replace("{template_id}", id),
  // getAppTemplates: process.env.REACT_APP_API_APP_MANAGER_TEMPLATELIST,
  deletePrompt: process.env.REACT_APP_API_PROMPT_MANAGER_DELETE,
  //getPromptTags:process.env.REACT_APP_API_PROMPT_MANAGER_PROMPT_TAG_LIST
}

const appManagerAPIUrls: any = {
  insertApp: process.env.REACT_APP_API_APP_MANAGER_CREATE,
  updateApp: process.env.REACT_APP_API_APP_MANAGER_UPDATE,
  publishApp: process.env.REACT_APP_API_APP_MANAGER_PUBLISH,
  getAppDetails: (appId: string) => process.env.REACT_APP_API_APP_MANAGER_DETAILS?.replace("{id}", appId),
  getPublishedAppDetails: (appId: string, type: any) => process.env.REACT_APP_API_APP_PUBLISHED_EDIT?.replace("{app_id}", appId)?.replace("{type}", type),
  getAppDetailsTemplate: (appId: string) => process.env.REACT_APP_API_APP_MANAGER_DETAILS_TEMPLATE?.replace("{id}", appId),
  getAppTemplateDetails: process.env.REACT_APP_API_APP_MANAGER_TEMPLATELIST,
  deleteApp: (appId: string) => process.env.REACT_APP_API_APP_MANAGER_DELETE?.replace("{id}", appId),
  listAppHistory: (appId: string) => process.env.REACT_APP_API_APP_MANAGER_HISTORYLIST?.replace("{app_id}", appId),
  listAppVersions: (appId: string, type: string) => process.env.REACT_APP_API_APP_MANAGER_VERSION?.replace("{app_id}", appId).replace("{type}", type),
  listRunHistory: (appId: string) => process.env.REACT_APP_API_APP_MANAGER_APP_RUN_HISTORY?.replace("{app_id}", appId),
  listAppPublished: process.env.REACT_APP_API_APP_MANAGER_LIST_PUBLISHED,
  listAppDraft: process.env.REACT_APP_API_APP_MANAGER_LIST_DRAFT,
  //testApp: `/orchestrator/app_orchestrator`,
  getAppTags: () => process.env.REACT_APP_API_APP_MANAGER_APP_TAGS,
  bulkRunStatus: (appId: any, execId: any) => process.env.REACT_APP_API_APP_MANAGER_APP_BULK_RUN_STATUS?.replace("{app_id}", appId).replace("{execution_id}", execId),
  appShareTest: (app_id: any) => process.env.REACT_APP_API_APPSHARE_ORCHESTRATOR?.replace("{appId}", app_id),
  getAppShareDetails: (appId: string) => process.env.REACT_APP_API_APPSHARE_MANAGER_DETAILS?.replace("{id}", appId),
  appShareLinkGeneration: process.env.REACT_APP_API_APPSHARE_LINK_GENERATION,
  appShareLinkValidation: process.env.REACT_APP_API_APPSHARE_LINK_VALIDATION,
  bulkrunStatusApp: process.env.REACT_APP_API_BULKRUN_STATUS_APP_SPECIFIC,
  bulkrunTerminateRun: process.env.REACT_APP_API_BULK_RUN_TERMINATE_RUN,
  bulkrunExecuteBulkrun: process.env.REACT_APP_API_BULK_RUN_EXECUTE_RUN,
  appApiIntegate: process.env.REACT_APP_API_APP_API_INTEGRATE,
  setAsDefault: process.env.REACT_APP_API_APP_MANAGER_SETASDEFAULT_VERSION,
  setAsTemplate: process.env.REACT_APP_API_APP_MANAGER_SETASTEMPLATE,
  listTemplateVersions: (appId: string) => process.env.REACT_APP_API_APP_MANAGER_TEMPLATE_VERSION?.replace("{app_id}", appId),
  appInsights: process.env.REACT_APP_API_SINGLE_APP_INSIGHT,
  appInsightsWhole: process.env.REACT_APP_API_APP_INSIGHT,
  addAppFeedback: process.env.REACT_APP_API_APP_MANAGER_FEEDBACK,

}

const orchestratorApiUrls: any = {
  promptChat: process.env.REACT_APP_API_PROMPT_ORCHESTRATOR,
  appChat: process.env.REACT_APP_API_APP_ORCHESTRATOR
}



const systemConfigUrls: any = {
  getSystemConfig: process.env.REACT_APP_API_SYSTEM_CONFIG_FETCH_DATA,
  getTags: process.env.REACT_APP_API_SYSTEM_MANAGER_TAGS,
  getLLMModels: process.env.REACT_APP_API_SYSTEM_MANAGER_LLM_MODELS,
  getVectorDb: process.env.REACT_APP_API_SYSTEM_MANAGER_VECTOR_DB,
  getVisionModels: process.env.REACT_APP_API_SYSTEM_MANAGER_VISION_MODELS,
  getVoiceModels: process.env.REACT_APP_API_SYSTEM_MANAGER_VOICE_MODELS,
  getModels: process.env.REACT_APP_API_SYSTEM_MANAGER_MODELS,
  getEmbeddingModels: process.env.REACT_APP_API_SYSTEM_MANAGER_EMBEDDING_MODLES,
}
const fileUrls: any = {
  uploadFile:(action: any)=> process.env.REACT_APP_API_CONTENT_FILEMANAGER_UPLOADFILE?.replace("{action}",action),
  upoadFileAsync:'/uploadContentAsync?code=L4FIatbQomJcKkGE94dtBoTJq2ckubg_CNxExzhOVnLiAzFuE_5EMA==',
  uploadFileUsingLink: process.env.REACT_APP_API_FILEUPLOAD,
  uploadFileSASgenration: process.env.REACT_APP_API_SAS_TOKEN_GENERATE_FILEUPLOAD
}

const userManagementUrls: any = {
  enableDisableUser: process.env.REACT_APP_API_USER_MANAGEMENT_ENABLE_AND_DISABLE,
  listUsers: (tenantType: any) => process.env.REACT_APP_API_USER_MANAGEMENT_LIST_USERS?.replace("{tenantType}", tenantType),
  updateUserDeatils: process.env.REACT_APP_API_USER_MANAGEMENT_UPDATE_USER_DETAILS,
  createUser: process.env.REACT_APP_API_USER_MANAGEMENT_CREATE_USER_DETAILS,
  deleteUser: (userId: any) => process.env.REACT_APP_API_USER_MANAGEMENT_DELETEE_USER?.replace("{user_id}", userId),
  getUserDetails: (userId: any) => process.env.REACT_APP_API_USER_MANAGEMENT_GET_USER_DETAILS?.replace("{user_id}", userId)
}

const tenantManagement: any = {
  listTenants: process.env.REACT_APP_API_TENANT_MANAGEMENT_LIST_TENANTS,
  createTenants: process.env.REACT_APP_API_TENANT_MANAGEMENT_CREATE_TENANTS,
  getTenantDetails: process.env.REACT_APP_API_TENANT_MANAGEMENT_GET_TENANT_DETAILS,
  updateTenants: process.env.REACT_APP_API_TENANT_MANAGEMENT_UPDATE_TENANT,
  deleteTenant: (Id: any) => process.env.REACT_APP_API_TENANT_MANAGEMENT_DELETE_TENANT?.replace("{tenant_doc_id}", Id),
  disableTenant: (Id: any) => process.env.REACT_APP_API_TENANT_MANAGEMENT_DISABLE_TENANT?.replace("{tenant_doc_id}", Id),
  enableTenant: (Id: any) => process.env.REACT_APP_API_TENANT_MANAGEMENT_ENABLE_TENANT?.replace("{tenant_doc_id}", Id),
  getTenantGroups: (Id: any) => process.env.REACT_APP_API_TENANT_MANAGEMENT_GET_TENANT_GROUPS?.replace("{tenant_id}", Id),
  saveTenantGroups: process.env.REACT_APP_API_TENANT_MANAGEMENT_SAVE_TENANT_GROUPS,
  getSubscriptionDetails: process.env.REACT_APP_API_TENANT_MANAGEMENT_GET_SUBSCRIPTION_DETAILS,

}

const accessManagement: any = {
  listAccess: process.env.REACT_APP_API_ACCESS_MANAGEMENT_LIST_ACCESS,
  deleteAccess: (access_id: any) => process.env.REACT_APP_API_ACCESS_MANAGEMENT_DELETE_ACCESS?.replace("{access_id}", access_id)
}

const rolesManagement: any = {
  listRoles: process.env.REACT_APP_API_ROLE_MANAGEMENT_LIST_ROLES,
  createRole: process.env.REACT_APP_API_ROLE_MANAGEMENT_CREATE_ROLES,
  updateRole: process.env.REACT_APP_API_ROLE_MANAGEMENT_UPDATE_ROLES,
  deleteRole: (roleId: any) => process.env.REACT_APP_API_ROLE_MANAGEMENT_DELETE_ROLES?.replace("{role_id}", roleId),
  getUserMappedRoles: (role:any) =>process.env.REACT_APP_API_ROLE_MANAGEMENT_GET_USER_MAPPED_ROLES?.replace('{role}', role),

}

const packageManagement: any = {
  listPackages: process.env.REACT_APP_API_PACKAGE_MANAGEMENT_LIST_PACKAGES,
  createPackages: process.env.REACT_APP_API_PACKAGE_MANAGEMENT_CREATE_PACKAGES,
  updatePackages: process.env.REACT_APP_API_PACKAGE_MANAGEMENT_UPDATE_PACKAGES,
  deletePackages: (packageId: any) => process.env.REACT_APP_API_PACKAGE_MANAGEMENT_DELETE_PACKAGES?.replace("{package_id}", packageId),
  listAppForPackage: process.env.REACT_APP_API_PACKAGE_MANAGEMENT_LIST_APPS_PACKAGES,
  listAppGeneral: process.env.REACT_APP_API_PACKAGE_MANAGEMENT_LIST_APPS_GENERAL
}

const productManagement: any = {
  listProducts: process.env.REACT_APP_API_PRODUCT_MANAGEMENT_LIST_PRODUCTS,
  updateProducts: process.env.REACT_APP_API_PRODUCT_MANAGEMENT_UPDATE_PRODUCTS
}


const integrationManagement: any = {
  listIntegrations: process.env.REACT_APP_API_INTEGRATION_MANAGEMENT_LIST_INTEGRATIONS,
  masterTenantIntegrations: process.env.REACT_APP_API_INTEGRATION_MANAGEMENT_MASTER_TENANT_INTEGRATIONS,
  deleteIntegration: (integrationId: any) => process.env.REACT_APP_API_INTEGRATION_MANAGEMENT_DELETE_INTEGRATIONS?.replace("{integration_id}", integrationId),
  createIntegration: process.env.REACT_APP_API_INTEGRATION_MANAGEMENT_CREATE_INTEGRATIONS,
  updateIntegration: process.env.REACT_APP_API_INTEGRATION_MANAGEMENT_UPDATE_INTEGRATIONS,
}

const featureManagement: any = {
  featureSetings: process.env.REACT_APP_API_FEATURE_MANAGER_FEATURE_SETTINGS,
  updateStatus: process.env.REACT_APP_API_FEATURE_MANAGER_UPDATE_FEATURE_SETTINGS_STATUS,
  createFeatureIntegration: process.env.REACT_APP_API_FEATURE_MANAGER_CREATE_FEATURE_INTEGRATION_SETTINGS,
  addFolderGroup: (type: any) => process.env.REACT_APP_API_FEATURE_MANAGER_ADD_CATEGORIES_FOLDER?.replace("{type}", type),
  updateTicketType: (type: any) => process.env.REACT_APP_API_FEATURE_MANAGER_UPDATE_TICKET_TYPES?.replace("{type}", type),
  updateCompanyInfo: process.env.REACT_APP_API_FEATURE_MANAGER_UPDATE_COMPANY_INFO,
  updatePersona: process.env.REACT_APP_API_FEATURE_MANAGER_UPDATE_PERSONA,
  getFeatureAppDetails: (appId: any) => process.env.REACT_APP_API_FEATURE_MANAGER_GET_APP_FEATURE_DETAILS?.replace("{app_id}", appId),
  getPacakageDetails: (type: any) => process.env.REACT_APP_API_FEATURE_MANAGER_GET_PACKAGE_DETAILS?.replace('{type}', type),
  updateFeatureSettings: process.env.REACT_APP_API_FEATURE_MANAGER_UPDATE_FEATURE_SETTINGS,
  getEndPointUrl: process.env.REACT_APP_API_FEATURE_MANAGER_END_POINT_URL,
}

const AutotagListing: any = {
  listAutoTag:(type: any) => process.env.REACT_APP_API_AUTOTAGLISTS?.replace('{type}', type),
}


const ControlroomApi: any = {
  gettreeData: process.env.REACT_APP_API_STARTTREEAPI,
  sourcetreeData: process.env.REACT_APP_API_SOURCETREEAPI
}

const chatBotApi: any = {
  chatBotTest: process.env.REACT_APP_API_CHATBOT_API,
  chatBotTestAdithyaBirla: process.env.REACT_APP_API_CHATBOT_API_ADITYA_BIRLA,
}

const autoSolveApis: any = {
  getWidgetDetails: (tool_id: any) => process.env.REACT_APP_API_WIDGET_MANAGMENT_GET_WIDGET_DETAILS?.replace("{tool_id}", tool_id),
  deleteWidget: (tool_id: any) => process.env.REACT_APP_API_WIDGET_MANAGMENT_DELETE_WIDGET?.replace("{tool_id}", tool_id),
  getBotDetails: (botId: any) => process.env.REACT_APP_API_AUTO_SOLVE_GET_BOT_DETAILS?.replace("{bot_id}", botId),
  updateBot: process.env.REACT_APP_API_AUTO_SOLVE_UPDATE_BOT,
  testBot: process.env.REACT_APP_API_AUTO_SOLVE_TEST_BOT,
  getAgentList: (tenant_feature_id: any, app_feature_id: any)  => process.env.REACT_APP_API_AUTO_SOLVE_GET_AGENT_LIST?.replace("{tenant_feaure_id}", tenant_feature_id)?.replace('{app_feature_id}', app_feature_id),
  getInstallationList: process.env.REACT_APP_API_AUTO_SOLVE_GET_INSTALLATION_LIST,
  createAgent: process.env.REACT_APP_API_WIDGET_MANAGEMENT_CREATE_AGENT,
  createBot: process.env.REACT_APP_API_AUTO_SOLVE_CREATE_BOT,
  updateAgent: process.env.REACT_APP_API_WIDGET_MANAGEMENT_UPDATE_AGENT,
  deleteAgent: (agentId: any) => process.env.REACT_APP_API_WIDGET_MANAGEMENT_DELETE_AGENTS?.replace("{id}", agentId),
  getAgentTestHistory: (agentId: any) => process.env.REACT_APP_API_AUTO_SOLVE_AGENT_TEST_HISTORY?.replace("{agent_id}", agentId),
  getAgentRunHistory: (agentId: any) => process.env.REACT_APP_API_AUTO_SOLVE_AGENT_RUN_HISTORY?.replace("{agent_id}", agentId),
  getBotRunHistory: (botId: any) => process.env.REACT_APP_API_AUTO_SOLVE_BOT_RUN_HISTORY?.replace("{bot_id}", botId),
  getBotRunHistoryOfConversation: (botId: any, conversationId: any) => process.env.REACT_APP_API_AUTO_SOLVE_BOT_RUN_HISTORY_OF_CONVERSATION?.replace("{bot_id}", botId).replace("{conversation_id}", conversationId),
  getAgentAssistConversationList: process.env.REACT_APP_API_AGENTASSIST_CONVERSATON_LIST,
  getAgentAssistConversationDetail: (conversationId: any) => process.env.REACT_APP_API_AGENTASSIST_CONVERSATION_DETAIL?.replace("{conversation_id}", conversationId),
  generateCode:process.env.REACT_APP_API_AUTO_SOLVE_GENERATE_CODE,
  getAutosolveConversations: process.env.REACT_APP_API_AUTOSOLVE_CONVERSATIONS,
  getBotTestHistory: (botId: any,conversationId:any) => process.env.REACT_APP_API_AUTO_SOLVE_BOT_TEST_HISTORY?.replace("{bot_id}", botId)?.replace("{conversation_id}", conversationId),
  saveFeedBackAutoSolve:process.env.REACT_APP_API_AUTO_SOLVE_BOT_FEEDBACK,
  autoSolveMetaData:process.env.REACT_APP_API_AUTO_SOLVE_META_DATA,
  downloadCsvReport:process.env.REACT_APP_API_AUTO_SOLVE_DOWNLOAD_CSV_REPORT,
  getMasterData:process.env.REACT_APP_API_AUTO_SOLVE_MASTER_DATA,
  cloneAutosolveFeature:process.env.REACT_APP_API_AUTO_SOLVE_CLONE_FEATURE,
  createAutoSolveFeature:process.env.REACT_APP_API_AUTO_SOLVE_CREATE_FEATURE
}

const widgetManagement: any = {
  listWidgets: process.env.REACT_APP_API_WIDGET_MANAGEMENT_LIST_WIDGETS,
  listWidgetTypes: process.env.REACT_APP_API_WIDGET_MANAGEMENT_LIST_WIDGETS_TYPES,
  createWidgets: process.env.REACT_APP_API_WIDGET_MANAGEMENT_CREATE_WIDGETS,
  deleteWidgets: (widgetId: any) => process.env.REACT_APP_API_WIDGET_MANAGEMENT_DELETE_WIDGETS?.replace("{widget_id}", widgetId),
  testWidgets: process.env.REACT_APP_API_WIDGET_MANAGEMENT_TEST_WIDGETS,
  getToolsList: process.env.REACT_APP_API_WIDGET_MANAGEMENT_LIST_TOOLS,
  updateWidgets: process.env.REACT_APP_API_WIDGET_MANAGMENT_UPDATE_WIDGET,
  widgetHistory: (tool_id: any) => process.env.REACT_APP_API_GET_WIDGET_HISTORY?.replace("{tool_id}", tool_id),
}

export {
  promptManagerAPIUrls, systemConfigUrls, contentFolderApiUrls,
  fileManagerAPIUrls, appManagerAPIUrls, fileUrls, userManagementUrls, orchestratorApiUrls, tenantManagement, accessManagement, rolesManagement, packageManagement, productManagement,
  integrationManagement, featureManagement, AutotagListing, ControlroomApi, chatBotApi, autoSolveApis, widgetManagement
}


