import { useState, createContext, useContext, useRef } from 'react'
import { Modal } from 'antd'
import WizrText from '../../components/WizrText'
import CreateUpdateFolderForm from '../../components/forms/CreateUpdateFolder'
import DeleteFileForm from '../../components/forms/DeleteFile'
import MultiUploadList from '../../components/forms/MultiUploadList'
import DeleteFolderForm from '../../components/forms/DeleteFolder'
import { styled } from 'styled-components'
import WizrFlexLayout from '../../components/WizrFlexLayout'
import AddTagsForm from '../../components/forms/AddTags'
import AddExamplesForm from '../../components/forms/AddExamples'
import AddCommentsForm from '../../components/forms/AddComments'
import AddParameterForm from '../../components/forms/AddParameter'
import LogoutConfirm from '../../components/forms/Logout'
import ConfirmationForm from '../../components/forms/Confirmation'
import UploadUrlForm from '../../components/forms/UploadUrl'
import CreateAppForm from '../../components/forms/CreateAppForm'
import FilePreviewer from '../../components/forms/FilePreviewer'
import selectTenant from '../../components/forms/SelectTenant'
import EditUserDetails from '../../components/popovers/EditUserDetails'
import ProductUpdate from '../../components/forms/ProductUpdate'
import AddRoles from '../../components/forms/AddRoles'
import AddPackages from '../../components/forms/AddPackages'
import AddIntegrations from '../../components/forms/AddIntegrations'
import CreateGroup from '../../components/forms/CreateGroup'
import CreateUpdatePastTicketsForm from '../../components/forms/CreatePastTickets'
import TicketSettings from '../../components/forms/TicketSettings'
import Persona from '../../components/forms/Persona'
import AddName from '../../components/forms/AddName'
import CreateTeam from '../../components/forms/CreateTeam'
import ImportPastTickets from '../../components/forms/ImportPastTickets'
import ControlroomFilter from '../../components/forms/ControlroomFilter'

const StyledLayout = styled(WizrFlexLayout)`
  padding: 10px 0px 0px 0px;
`

const MODAL_TYPES = {
  CREATE_UPDATE_FOLDER: 'CREATE_UPDATE_FOLDER',
  UPDATE_FILE: 'UPDATE_FILE',
  DELETE_FILE: 'DELETE_FILE',
  MULTI_FILE_UPLOAD: 'MULTI_FILE_UPLOAD',
  DELETE_FOLDER: 'DELETE_FOLDER',
  ADD_TAGS: 'ADD_TAGS',
  ADD_EXAMPLES: 'ADD_EXAMPLES',
  ADD_COMMENTS: 'ADD_COMMENTS',
  ADD_PARAMETER: 'ADD_PARAMETER',
  EDIT_PARAMETER: 'EDIT_PARAMETER',
  LOGOUT: 'LOGOUT',
  CONFIRMATION: 'CONFIRMATION',
  UPLOAD_URL: 'UPLOAD_URL',
  CREATE_APP: 'CREATE_APP',
  PREVIEW_FILE: 'PREVIEW_FILE',
  SELECT_TENANT:'SELECT_TENANT',
  EDIT_USER_DETAILS:"EDIT_USER_DETAILS",
  UPDATE_PRODUCTS:"UPDATE_PRODUCTS",
  CREATE_ROLES:"CREATE_ROLES",  
  UPDATE_ROLES:"UPDATE_ROLES",
  CREATE_PACKAGES:"CREATE_PACKAGES",
  UPDATE_PACKAGES:"UPDATE_PACKAGES",
  CREATE_INTEGRATION:"CREATE_INTEGRATION", 
  EDIT_INTEGRATION:"EDIT_INTEGRATION",  
  CREATE_GROUP :'CREATE_GROUP',
  EDIT_GROUP:'EDIT_GROUP',
  CREATE_UPDATE_PASTTICKKETS: 'CREATE_UPDATE_PASTTICKKETS',
  CREATE_TICKET_TYPE :'CREATE_TICKET_TYPE',
  CREATE_PERSONA:'CREATE_PERSONA',
  EDIT_TICKET_TYPE:'EDIT_TICKET_TYPE',
  UPDATE_PERSONA:'UPDATE_PERSONA',
  CREATE_AUTO_SOLVE:'CREATE_AUTO_SOLVE',
  CLONE_AUTO_SOLVE:'CLONE_AUTO_SOLVE',
  CREATE_TEAMS:'CREATE_TEAMS',
  IMPORT_PAST_TICKETS:'IMPORT_PAST_TICKETS',
  CONTROLROOM_FILTER: 'CONTROLROOM_FILTER',
  CLONE_FEATURE:'CLONE_FEATURE'
  
}

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.CREATE_UPDATE_FOLDER]: {
    title: 'New Folder',
    Component: CreateUpdateFolderForm,
  },
  [MODAL_TYPES.UPDATE_FILE]: {
    title: 'Update Folder',
    Component: CreateUpdateFolderForm,
  },
  [MODAL_TYPES.DELETE_FILE]: {
    title: 'Delete',
    Component: DeleteFileForm,
  },
  [MODAL_TYPES.MULTI_FILE_UPLOAD]: {
    title: 'Upload Files',
    Component: MultiUploadList,
  },
  [MODAL_TYPES.DELETE_FOLDER]: {
    title: 'Delete',
    Component: DeleteFolderForm,
  },
  [MODAL_TYPES.ADD_TAGS]: {
    title: 'Add Tags',
    Component: AddTagsForm,
  },
  [MODAL_TYPES.ADD_EXAMPLES]: {
    title: 'Add Examples',
    Component: AddExamplesForm,
  },
  [MODAL_TYPES.ADD_COMMENTS]: {
    title: 'Add Comments',
    Component: AddCommentsForm,
  },
  [MODAL_TYPES.ADD_PARAMETER]: {
    title: 'Add Parameter',
    Component: AddParameterForm,
  },
  [MODAL_TYPES.EDIT_PARAMETER]: {
    title: 'Edit Parameter',
    Component: AddParameterForm,
  },
  [MODAL_TYPES.LOGOUT]: {
    title: 'Logout',
    Component: LogoutConfirm,
  },
  [MODAL_TYPES.CONFIRMATION]: {
    title: 'Confirmation',
    Component: ConfirmationForm,
  },
  [MODAL_TYPES.UPLOAD_URL]: {
    title: 'Upload URL',
    Component: UploadUrlForm,
  },
  [MODAL_TYPES.CREATE_APP]: {
    title: 'Tools',
    Component: CreateAppForm,
  },
  [MODAL_TYPES.PREVIEW_FILE]: {
    title: 'File Preview',
    Component: FilePreviewer,
    customProps: {
      height: '100vh',
    },
  },
  [MODAL_TYPES.SELECT_TENANT]: {
    title: 'Select Tenant',
    Component: selectTenant,
  },
  [MODAL_TYPES.EDIT_USER_DETAILS]: {
    title: 'Edit user details',
    Component: EditUserDetails,
  },
  [MODAL_TYPES.UPDATE_PRODUCTS]: {
    title: 'Products',
    Component: ProductUpdate,
  },
  [MODAL_TYPES.CREATE_ROLES]:{
    title:"Create Roles",
    Component:AddRoles
  },
  [MODAL_TYPES.UPDATE_ROLES]:{
    title:"Update Role",
    Component:AddRoles
  },
  [MODAL_TYPES.CREATE_PACKAGES]:{
    title:"Create Packages",
    Component:AddPackages
  },
  [MODAL_TYPES.UPDATE_PACKAGES]:{
    title:"Update Packages",
    Component:AddPackages
  },
  [MODAL_TYPES.CREATE_INTEGRATION]:{
    title:"Create new Integration",
    Component:AddIntegrations
  },
  [MODAL_TYPES.EDIT_INTEGRATION]:{
    title:"Edit Integrations",
    Component:AddIntegrations
  },
  [MODAL_TYPES.CREATE_GROUP]:{
    title:" Create Group",
    Component:CreateGroup
  },
  [MODAL_TYPES.EDIT_GROUP]:{
    title:" Edit Group",
    Component:CreateGroup
  },
  [MODAL_TYPES.CREATE_TICKET_TYPE]:{
    title:"Create ",
    Component:TicketSettings
  },
  [MODAL_TYPES.EDIT_TICKET_TYPE]:{
    title:"Edit ",
    Component:TicketSettings
  },
  [MODAL_TYPES.CREATE_PERSONA]:{
    title:'Create Persona',
    Component:Persona
  },
  [MODAL_TYPES.UPDATE_PERSONA]:{
    title:'Update Persona',
    Component:Persona
  },
  [MODAL_TYPES.CREATE_UPDATE_PASTTICKKETS]: {
    title: 'Add Group',
    Component: CreateUpdatePastTicketsForm,
  },
  [MODAL_TYPES.CREATE_AUTO_SOLVE]: {
    title: 'Add New Auto Solve',
    Component: AddName,
  },
  [MODAL_TYPES.CLONE_AUTO_SOLVE]: {
    title: 'Clone Auto Solve',
    Component: AddName,
  },
  [MODAL_TYPES.CLONE_FEATURE]: {
    title: 'Clone Feature',
    Component: AddName,
  },
  [MODAL_TYPES.CREATE_TEAMS]: {
    title: 'Create Teams',
    Component: CreateTeam,
  },
  [MODAL_TYPES.IMPORT_PAST_TICKETS]: {
    title: 'Import',
    Component: ImportPastTickets,
  },
  [MODAL_TYPES.CONTROLROOM_FILTER]: {
    title: '',
    Component: ControlroomFilter,
  },
}

type GlobalModalContext = {
  showModal: (modalType: string, modalProps?: any) => void
  hideModal: () => void
  store: any
}

const initalState: GlobalModalContext = {
  showModal: () => {},
  hideModal: () => {},
  store: {},
}

const WizrModalContext = createContext(initalState)
const useWizrModalContext = () => useContext(WizrModalContext)

const WizrModalProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [store, setStore] = useState<any>()
  const { modalType, modalProps } = store || {}

  const showModal = (modalType: string, modalProps: any = {}) => {
    setStore({
      ...store,
      modalType,
      modalProps,
    })
  }

  const hideModal = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {},
    })
  }

  const renderComponent = (): JSX.Element => {
    const ModalComponent = MODAL_COMPONENTS[modalType]?.Component
    const modalTitle = MODAL_COMPONENTS[modalType]?.title
    const customProps = MODAL_COMPONENTS[modalType]?.customProps

    if (!modalType || !ModalComponent) {
      return <></>
    }
    return (
      <Modal
        title={<WizrText type='h6'>{modalTitle}</WizrText>}
        open={true}
        footer={null}
        onCancel={hideModal}
        forceRender={false}
        maskClosable={true}
        closeIcon={null}
        destroyOnClose={true}
        style={customProps}
      >
        <StyledLayout alignItems='flex-start' background='#FFF'>
          <ModalComponent id='global-modal' {...modalProps} />
        </StyledLayout>
      </Modal>
    )
  }

  return (
    <WizrModalContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </WizrModalContext.Provider>
  )
}

export { MODAL_TYPES, useWizrModalContext, WizrModalProvider }
